import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
import { useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import "./business-application.css";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
import SecondModuleImage from "../../../assets/business-application/business-application-second-module.jpg";
import BusimessApplicationService1 from "../../../assets/business-application/business-apploication-our-service-1.webp";
import BusimessApplicationService2 from "../../../assets/business-application/business-apploication-our-service-2.webp";
import BusimessApplicationService3 from "../../../assets/business-application/business-apploication-our-service-3.webp";
import OurClientImage1 from "../../../assets/business-application/business-application-client-1.webp";
import OurClientImage2 from "../../../assets/business-application/business-application-client-2.webp";
import OurClientImage3 from "../../../assets/business-application/business-application-client-3.webp";
import OurClientImage4 from "../../../assets/business-application/business-application-client-4.webp";
import OurClientImage5 from "../../../assets/business-application/business-application-client-5.webp";
import OurClientImage6 from "../../../assets/business-application/business-application-client-6.webp";
import Aos from 'aos';
import 'aos/dist/aos.css'
function BusinessApplication() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="business-application-expertise-banner">
        <div class="container">
          <div class="business-application-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              BUSINESS APPLICATION
            </h5>
            <h1 className="clr-text-white">
              Unified cloud solutions
              for your enterprise
            </h1>
            <div className="business-application-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="business-application-expertise-second-module-parent">
        <div class="container">
          <div className="business-application-expertise-second-module-flex">
            <div className="business-application-expertise-second-module-column one">
              <div data-aos="slide-right" className="business-application-parralax-container">
                <h1 className="business-application-stroke-font">Business</h1>
                <h1 className="business-application-stroke-font">Application</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="business-application-expertise-second-module-column solid-bg">
              <div className="business-application-expertise-solid-bg-content">
                <p className="clr-txt business-application-content">Your business requires robust, feature-rich,
                  extensible, secure, and highly integrated
                  applications to efficiently manage its complex
                  operations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <section class="business-application-third-module-section">
          <div class="business-application-third-module-section__item column-one">
            <img alt="Business Application Coding" width="100%" height="100%" className="img-fluid" src={SecondModuleImage} />
          </div>

          <div class="business-application-third-module-section__item column-two">
            <p className="clr-txt business-application-second-module-content my-3">
              The right solution and technology provider can save your
              organization from expensive upgrades, customization,
              and change management. Prosouls Inc recommends
              ndustry-leading Microsoft Dynamics 365 to simplify,
              centralize, and scale the management of your business.
              Its intuitive user experience, modular design, unified ERP
              & CRM capabilities, secure-by-design applications, and
              rapid implementation cycle helps Dynamics 365 offer
              high value and ROI.
            </p>
          </div>
          <div className="business-application-floating-container">
            <p className="clr-txt business-application-second-module-content m-0">
              As a tier-1 Microsoft Cloud Solution Provider (CSP) and Microsoft
              Gold Partner across ERP, CRM, Cloud Productivity, and many other
              competencies, Prosouls Inc has a complete range of technical
              and business consulting capabilities. Decades of experience and a
              large team of certified experts make us ideally suited to work on
              implementing, enhancing, and managing every level of your global
              enterprise applications footprint.
            </p>
          </div>
        </section>
      </div>
      <div className="container my-5">
        <div class="business-application-eight">
          <h1 className="clr-txt">OUR SERVICES</h1>
        </div>
      </div>
      <div className="container">
        <div className="products-expertise-cards-parent">
          <div className="business-application-cards-flex">
            <div className="business-application-cards-column column-one">
              <div className="business-application-card-icon">
                <img alt="Fast Track" width="100%" height="100%" className="business-application-card-icon-img" src={BusimessApplicationService1} />
              </div>
              <div className="business-application-card-title">
                <h3 className="clr-txt">Fast-track
                  Implementation</h3>
              </div>
              <div className="business-application-card-details">
                <ul>
                  <li>Ready-to-go solutions for
                    fast-track implementations
                    <ul>
                      <li>Industry-specific solutions</li>
                      <li>Horizontal accelerators</li>
                      <li>PowerApps & Flow apps</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="business-application-cards-column column-two">
              <div className="business-application-card-icon">
                <img alt="Unified Cloud Solutions" width="100%" height="100%" className="business-application-card-icon-img" src={BusimessApplicationService2} />
              </div>
              <div className="business-application-card-title">
                <h3 className="clr-txt">Unified cloud solutions
                  for your enterprise</h3>
              </div>
              <div className="business-application-card-details">
                <ul>
                  <li>Diagnostics & scoping exercises</li>
                  <li>Assessments
                    <ul>
                      <li>ERP upgrade</li>
                      <li>Automation via RPA</li>
                      <li>Helpdesk & support</li>
                    </ul>
                  </li>
                  <li>Trials, POCs & demonstrations</li>
                </ul>
              </div>
            </div>
            <div className="business-application-cards-column column-three">
              <div className="business-application-card-icon">
                <img alt="Optimization" width="100%" height="100%" className="business-application-card-icon-img" src={BusimessApplicationService3} />
              </div>
              <div className="business-application-card-title">
                <h3 className="clr-txt business-application-mng-height">Optimization</h3>
              </div>
              <div className="business-application-card-details">
                <ul>
                  <li>Upgrade and migration
                    <ul>
                      <li>On-premises to cloud</li>
                    </ul>
                  </li>
                  <li>Platform modernization</li>
                  <li>App modernization</li>
                  <li>24x7 support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="busniess-application-fourth-module-parent">
        <div className="container">
          <div className="business-application-fourth-module-heading-parent">
            <h1 className="clr-txt">AREAS OF EXPERTISE</h1>
          </div>
          <div className="business-application-fourth-module-flex">
            <div className="business-application-fourth-module-column one">
             <div className="business-application-fourth-module-inner-column">
             <h3 className="clr-txt-blue m-0">ERP</h3>
              <ul>
                <li>Microsoft Dynamics 365 for Finance and Operations
                  <ul>
                    <li>Financials</li>
                    <li>Supply Chain</li>
                    <li>Manufacturing</li>
                  </ul>
                </li>
              </ul>
             </div>
             <div className="business-application-fourth-module-inner-column two">
             <h3 className="clr-txt-blue m-0">CRM</h3>
              <ul>
                <li>Microsoft Dynamics 365 for Sales</li>
                <li>Microsoft Dynamics 365 for Field Service</li>
                <li>Microsoft Dynamics 365 for Customer Service</li>
                <li>Microsoft Dynamics 365 for Marketing</li>
              </ul>
             </div>
            </div>
            <div className="business-application-fourth-module-column">
             <div className="business-application-fourth-module-inner-column">
             <h3 className="clr-txt-blue m-0">RETAIL</h3>
              <ul>
                <li>Microsoft Dynamics 365 for Retail</li>
                <li>Omnichannel</li>
              </ul>
             </div>
             <div className="business-application-fourth-module-inner-column two">
             <h3 className="clr-txt-blue m-0">HRM</h3>
              <ul>
                <li>Microsoft Dynamics 365 Human Resources
                  <ul>
                    <li>Payroll</li>
                  </ul>
                </li>
              </ul>
             </div>
             <div className="business-application-fourth-module-inner-column two">
             <h3 className="clr-txt-blue m-0">BI</h3>
              <ul>
                <li>Microsoft Power BI</li>
              </ul>
             </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container business-application-our-clinets">
        <div className="business-application-techs-title">
          <div class="business-application-tech-container">
            <div class="business-application-tech-item">
              <img alt="Rivayat" width="100%" height="100%" className="business-application-tech-img" src={OurClientImage1} />
            </div>
            <div class="business-application-tech-item">
              <img alt="Appster" width="100%" height="100%" className="business-application-tech-img" src={OurClientImage2} />
            </div>
            <div class="business-application-tech-item">
              <img alt="Getz Pharma" width="100%" height="100%" className="business-application-tech-img" src={OurClientImage3} />
            </div>
            <div class="business-application-tech-item">
              <img alt="3M" width="100%" height="100%" className="business-application-tech-img" src={OurClientImage4} />
            </div>
            <div class="business-application-tech-item">
              <img alt="Honda" width="100%" height="100%" className="business-application-tech-img" src={OurClientImage5} />
            </div>
            <div class="business-application-tech-item">
              <img alt="Aussie Chook" width="100%" height="100%" className="business-application-tech-img" src={OurClientImage6} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BusinessApplication;
