// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
import Home from "./home/home";
import Services from "./services/services";
import Work from "./work/work";
// import Navbarr from "./navbar/navbar";
import About from "./about/about";
import Client from "./clients/client";
// import ContactUs from "./contact/contact";
import Footer from "./footer/footer"
// import ReactWhatsapp from 'react-whatsapp';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ParticlesBackground from "./global-component/particle-background/particle-background";
const Mainscreen= () => {
    const [seo, setSeo] = useState([]);

    const fetchData = () => {
      fetch("https://cms.prosoulsinc.com/api/homeseo")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setSeo(data.data);
        });
    };
    useEffect(() => {
      fetchData();
    }, []);
    return (
        <>
        <Helmet>
        <title>{seo.meta_title}</title>
        <meta
          name="description"
          content={ seo.meta_description}
        />
        <meta name="keywords" content={ seo.meta_keyword} />
      </Helmet>
      <ParticlesBackground/>
   
              <Home /> <Services/> <Work/> <About/> <Client/> <Footer/>
              
        </>
    )
}


export default Mainscreen;