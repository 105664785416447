// import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import slide1 from "../../assets/services/Systems Development.webp";
import slide2 from "../../assets/services/Design and Branding.webp";
import slide3 from "../../assets/services/Packaging.webp";
import slide4 from "../../assets/services/Mobile App Development.webp";
import slide5 from "../../assets/services/Social Media Marketing.webp";
import slide6 from "../../assets/services/Architectural Visualization.webp";
import slide7 from "../../assets/services/Website Development.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import "./services.css";
// import { useRef } from "react";
// import Aos from 'aos';
// import 'aos/dist/aos.css'
function Services() {
  // useEffect(()=>{
  //   Aos.init({duration:1000})
  // })
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="services" id="service">
        {/* <div className='colm'>
  <div>
    <h3 className='heading' >WHAT WE BUILD <br /> servicesS AS GOOD <br /> AS IT LOOKS</h3>
    <p className='textservices' >Your online platforms need to services for your business,
      showing your audience what you can do for them. Great
      design combined with user-friendly features creates a
      compelling digital experience </p>
  </div>

</div> */}
        <div className="services-image">
          <Carousel
            responsive={responsive}
            arrows={false}
            infinite={true}
            indicators={false}
            autoPlay={true}
            interval={5000}
          >
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="System Development"
                  className="services-image"
                  src={slide1}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                  SYSTEMS <br/> DEVELOPMENT 
                </h3>
                <p className="textservices">
                Prosouls offers comprehensive system development services tailored to your project's unique demands and business objectives. Our services encompass a wide spectrum, ranging from IT strategy consultancy and robust technology roadmaps to the complete development of scalable solutions, including:
                <br/>
                <ul className="py-3 cstm-service-ul">
                  <li>E-COMMERCE</li>
                  <li>CHAT-BOT AUTOMATION</li>
                  <li>INTEGRATION SERVICE</li>
                  <li>CLOUD SOLUTIONS</li>
                  <li>BUSINESS PROCESS & RULES MANAGEMENT</li>
                  <li>ENTERPRISE MODERNIZATION</li>
                  <li>ANALYTIC AND BIG DATA</li>
                  <li>DEVOPS</li>
                </ul>
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Design and Branding"
                  className="services-image" 
                  src={slide2}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                  DESIGN & <br/> BRANDING
                </h3>
                <p className="textservices">
                Creating logos and brands necessitates both artistic ability and strategic knowledge. Our dedicated team of specialists have the creative skills to meet challenges of all sizes. Whether you are a start-up seeking a way to bring your story to life or a known brand looking to breathe new life into what you do, our expertise in creating meaningful logos and unique brand recognition will enable you and your customers to see your brand on a whole new level.
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide7}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                WEBSITE <br/> DEVELOPMENT
                </h3>
                <p className="textservices">
                The first thing prospective clients and customers see is your website. It is essential that it accurately reflects your company. We create enterprise-level websites for ambitious businesses that want to achieve more by transforming aspiration into success. We understand that simply having a website isn't enough. We'll get to the heart of what your customers want with your collaboration and then align this with your business objectives to produce a solid return on investment. 
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide6}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                3D ANIMATION & <br/> VISUALIZATION
                </h3>
                <p className="textservices">
                At Prosouls, we create stunning 3D Visualizations for any industry. Our team has extensive experience in the 3D rendering field. We are passionate about visual art. We being professional and easy to work with, provide exceptional quality of work at a great value. 3D visualizations excel in their capacity to present the interior of a space well before it's constructed. This enables designers and marketers to assess design ideas prior to their presentation to decision-makers and critical project stakeholders. At Prosouls we work with a passion for interior design, architecture, and art. Each project, regardless of its scale and purpose, is always in our good hands.
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide4}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                APPLICATION <br/> DEVELOPMENT
                </h3>
                <p className="textservices">Prosouls provides full-service application development, from design to execution and ongoing management. Our process covers everything from initial ideas to final delivery and post-installation support, whether the app serves individuals or businesses. Perfection is more than just a trait; it's a mindset we embrace. When you choose to collaborate with us, you can trust that you're teaming up with a formidable partner capable of transforming your digital aspirations into reality.</p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide3}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                  PRODUCT <br/> PACKAGING DESIGN
                </h3>
                <p className="textservices">Our team at Prosouls specializes in creating eye-catching packaging designs for retail products. We understand the importance of connecting a product's purpose with its packaging. Our goal is to attract interested buyers by crafting clean and uncluttered designs that highlight the reasons why customers should choose your product. Packaging design is an essential component in building brand identity, even if your primary sales channel is online. We focus on smart packaging label design to make your products stand out and appeal to your target audience.</p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide5}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                SOCIAL <br/> MEDIA MARKETING

                </h3>
                <p className="textservices">
                Start-ups and established brands can both benefit from social media marketing services. With the right social media marketing plan and campaign monitoring system, it can lead to increased search traffic, stronger SEO, healthier customer engagement and improved brand loyalty. The service we offer are:
                <br/>Develop Brand Awareness (Followers) Build Relationships (Engagement) Increasing Brand

                </p>
              </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Services;
