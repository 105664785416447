import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
import Navbarr from "../../navbar/navbar";
import { useState, useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import Stylesheet from "./business-process-outsourcing.css";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
import Aos from 'aos';
import 'aos/dist/aos.css'
function BusinessProcessOutsourcing() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="business-outsourcing-expertise-banner">
        <div class="container">
          <div class="business-outsourcing-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              BUSINESS PROCESS OUTSOURCING
            </h5>
            <h1 className="clr-text-white">
              Identify. Improve.
              Optimize.
            </h1>
            <div className="business-outsourcing-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="business-outsourcing-expertise-second-module-parent">
        <div class="container">
          <div className="business-outsourcing-expertise-second-module-flex">
            <div className="business-outsourcing-expertise-second-module-column one">
              <div data-aos="slide-right" className="business-outsourcing-parralax-container">
                <h1 className="business-outsourcing-stroke-font">Business</h1>
                <h1 className="business-outsourcing-stroke-font">Solutions</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="business-outsourcing-expertise-second-module-column solid-bg">
              <div className="business-outsourcing-expertise-solid-bg-content">
                <p className="clr-txt business-outsourcing-content">Prosouls utilizes a mix of highly skilled
                  resources, lean process and bespoke strategy
                  to offer a one-stop Business Process
                  Outsourcing (BPO) solution.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h4 className="clr-text-white text-center">
          Using our inherent strengths as one of the pioneers of digital technology in the country, we build on them
          to offer precise, efficient, and measurable BPO capabilities. Prosouls BPO assures the acceleration of your
          service delivery by transforming your business in terms of automation, quality management, multi-channel
          approaches, and digital transformation. All with impeccable transparency and accountability to ensure
          complete client satisfaction.
        </h4>
      </div>
      <div className="container my-5">
        <section class="business-outsourcing-third-module-section">
          <div class="business-outsourcing-third-module-section__item column-one">
            <p className="clr-txt mng-line-height mb-3">
              Prosouls has been a technical partner to <br />
              numerous international businesses,<br />
              government organizations, and industry<br />
              leaders. Many of these clients trust our BPO services<br />
              enough to have continued with us more than a<br />
              decade.
            </p>
            <p className="clr-txt mng-line-height mb-3">
              We have helped dozens of our clients grow and<br />
              transform their businesses using our BPO<br />
              solutions.
            </p>
            <p className="clr-txt mng-line-height mb-0">
              Prosouls BPO services have been<br />
              instrumental in the success of multiple<br />
              large-scale government efforts to digitize<br />
              land record management, enable the<br />
              efficient storage and retrieval of data for<br />
              financial institutes, and other public services.
            </p>
          </div>

          <div class="business-outsourcing-third-module-section__item column-two">
            <h1 className="clr-txt mb-5">Why Prosouls Inc’s BPO?</h1>
            <p className="clr-txt mng-line-height mb-0">
              20+ years of experience serving domestic and international clientele<br />
              Strong focus on close client relations and meeting KPIs<br />
              ISO 27001, ISO 20000, and ISO 9001 certified InfoSec and service management<br />
              Best-in-class methodologies replicate successes and scale operations<br />
              Processes based on Six Sigma best practices<br />
              PMP, COPC, and Six Sigma certified management<br />
              Over 1,500 trained BPO professionals with diverse skill sets<br />
              Quick ramp-up while maintaining consistently high-quality results<br />
              PCI-compliant contact centers<br />
              Multiple proprietary technologies accelerate service delivery<br />
              Centers of Excellence in multiple regions ensure business continuity
            </p>
          </div>
        </section>
      </div>
      <div className="container my-5">
        <div class="business-process-eight">
          <h1 className="clr-txt">Services Catalog</h1>
        </div>
        <section class="business-outsourcing-fourth-module-section">
          <div class="business-outsourcing-fourth-module-section__item column-one">
            <h2 className="clr-txt">Core Services:</h2>
            <h2 className="clr-txt">Mortgage & Title</h2>
            <h2 className="clr-txt">Contact Center</h2>
            <h2 className="clr-txt">Digital Marketing</h2>
          </div>
          <div class="business-outsourcing-fourth-module-section__item">
            {/* <img data-aos="fade-up"
     data-aos-anchor-placement="bottom-center" className="business-outsourcing-table-img" src={TableImage} /> */}
      <ul className="service-cataslog-ul">
               <li>
                <p className="clr-txt">Image scanning and processing</p>
                </li>
                <li>
                  <p className="clr-txt">Data entry</p>
                </li>
                <li>
                  <p className="clr-txt">Catalog population</p>
                </li>
                <li>
                  <p className="clr-txt">Chart reading and review</p>
                </li>
                </ul>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default BusinessProcessOutsourcing;
