import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
import Button from "react-bootstrap/Button";
import Navbarr from "../../navbar/navbar";
import * as React from "react";
import { useState, useEffect } from "react";
import { color } from "@mui/system";
// import { useState, useEffect } from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import Stylesheet from "./application-integration.css";
import FourthBgImage from "../../../assets/application-integration/1.webp";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
// import SecondModuleBg from '../../../assets/application-second-module-bg.png'
import Aos from "aos";
function BusinessApplication() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });

  return (
    <>
      <ParticlesBackground />
      <div className="expertise-banner">
        <div class="container">
          <div class="expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              APPLICATION INTEGRATION
            </h5>
            <h1 className="clr-text-white">
              Automatic information exchange with internal and external systems
            </h1>
            <div className="expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="application-development-expertise-second-module-parent">
        <div class="container">
          <div className="application-development-expertise-second-module-flex">
            <div className="application-development-expertise-second-module-column one">
              <div
                data-aos="slide-right"
                className="application-development-parralax-container"
              >
                <h1 className="application-development-stroke-font">
                  Application
                </h1>
                <h1 className="application-development-stroke-font">
                  integration
                </h1>
              </div>
            </div>
            <div
              data-aos="slide-left"
              className="application-development-expertise-second-module-column solid-bg"
            >
              <div className="application-development-expertise-solid-bg-content">
                <p className="clr-txt application-development-content">
                  Modern businesses use multiple digital applications and
                  systems to communicate internally between employees and
                  departments, as well as externally with customers, partners,
                  government agencies, and suppliers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <section class="application-integration-second-module-section">
          <div class="application-integration-second-module-section__item column-one">
            <img className="img-fluid" src={FourthBgImage} />
          </div>

          <div class="application-integration-second-module-section__item column-two">
            <p className="clr-txt application-development-content my-3">
              Prosouls inc assists its clients by offering application
              integration services that enable automatic sharing of relevant
              information between these stakeholders by linking various digital
              systems together.
            </p>
            <p className="clr-txt application-development-content my-3">
              Prosouls Inc’s application integration experts help your
              organization make more complete use of your business information
              to avoid “swivel-chair integration” and accelerate time to market.
              Your team members will spend less time managing isolated computer
              software systems and web applications, allowing them to pursue
              tasks that offer real business value.
            </p>
          </div>
        </section>
      </div>
      <div className="application-integration-fourth-module-parent">
        <div className="container">
          <div className="application-integration-section">
          <div className="application-integration-table-heading">
          <h1 className="clr-text-white">Application integration helps you…</h1>
        </div>
            <table className="bordered application-integration" >
              <tbody>
                <tr>
                  <td>
                    <h3 className="clr-txt-blue">Boost Efficiency</h3>
                    <p className="clr-txt">Process orders, generate reports, and make decisions without costly delays</p>
                  </td>
                  <td>
                    <h3 className="clr-txt-blue">Reduce Costs</h3>
                    <p className="clr-txt">Quickly centralize and unify business information without allocating team members and significant resources</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3 className="clr-txt-blue">Ensure Accuracy</h3>
                    <p className="clr-txt">Share precise, error-free business information with authorized users across departments, business functions, and partner organizations</p>
                  </td>
                  <td>
                    <h3 className="clr-txt-blue">Monetize Information Exchange</h3>
                    <p className="clr-txt">Create new revenue streams and establish API ecosystems that promote services driven by your business dataCreate new revenue streams and establish API ecosystems that promote services driven by your business data</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="expertise-fifth-module-parent">
        <div class="container">
          <div className="usp-card-parent">
            <div className="usp-card">
              <h3 className="clr-text-white m-0">Cross-Platform <br /> Expertise</h3>
              <p className="clr-text-white mt-2">
              We take a platform-agnostic
approach to integration, which
allows us to recommend the best
integration platform and technology
stack for your specific set of business
needs.
              </p>
            </div>
            <div className="usp-card">
              <h3 className="clr-text-white m-0">Modern Skills <br /> and Methods</h3>
              <p className="clr-text-white mt-2">
              Prosouls inc. integration professionals
apply modern integration techniques
to create efficient, easy to maintain
data exchange systems that maximize
return on your IT investments.
              </p>
            </div>
            <div className="usp-card">
              <h3 className="clr-text-white m-0">Continuous <br /> Improvement</h3>
              <p className="clr-text-white mt-2">
              Our experts monitor and assess your
integration workloads, detect changes
in requirements, and proactively
recommend performance
optimizations.

              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BusinessApplication;
