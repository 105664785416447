import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/logo (2).webp';
import fblogo from '../../assets/Facebook-Icon.png';
import Instalogo from '../../assets/Instagram-Icon.png';
// import lklogo from '../../assets/Linkedin-Icon.png';
// import bk from "../../assets/website-Nev-back.png";
import './navbar.css';
import React, { useState } from 'react';

function Navbarr() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const toggleDropdown1 = () => {
    setDropdownOpen1(!dropdownOpen1);
  };
 
  
  return (
    <div className='navbar-home'>
      <Navbar style={{ background: `#001a27cc`, marginTop: '-10px', width: "100%" }} className='navbar transparent navbar-inverse navbar fixed-top' collapseOnSelect expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="100%"
              height="100%"
              className="nav-brand-logo d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="nav navbar-nav flex-grow-1 cstm-mng-col justify-content-evenly">
            <NavDropdown
                className="cstm-dropdown"
                title="Services"
                id="services-dropdown"
                show={dropdownOpen}
                onClick={toggleDropdown}
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <NavDropdown.Item
                  href="/service/mobile-app-development"
                  className={`nav-dropdown-item ${dropdownOpen ? 'show' : ''}`}
                >
                  Mobile App Development
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  href="/service/animation"
                  className={`nav-dropdown-item ${dropdownOpen ? 'show' : ''}`}
                >
                  Animation
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  href="/service/website-development"
                  className={`nav-dropdown-item ${dropdownOpen ? 'show' : ''}`}
                >
                  Website Development
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/service/design-and-branding"
                  className={`nav-dropdown-item ${dropdownOpen ? 'show' : ''}`}
                >
                  Design and Branding
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/service/brochure"
                  className={`nav-dropdown-item ${dropdownOpen ? 'show' : ''}`}
                >
                  Brochure and Design
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/service/marketing"
                  className={`nav-dropdown-item ${dropdownOpen ? 'show' : ''}`}
                >
                  SMM - Marketing
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link href="/#work">Work</Nav.Link> */}
              <Nav.Link href="/#client">Client</Nav.Link>
              <NavDropdown
                className="cstm-dropdown1"
                title="Expertise"
                id="expertise-dropdown"
                show={dropdownOpen1}
                onClick={toggleDropdown1}
                onMouseEnter={toggleDropdown1}
                onMouseLeave={toggleDropdown1}
              >
                <NavDropdown.Item
                  href="/expertise/application-development"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Application Development
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/application-integration"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Application Integration
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/application-modernization"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Application Modernization
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/business-application"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Business Application
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/business-process-outsourcing"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Business Process Outsourcing
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/cloud-team"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Cloud Team
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/it-infrastructure"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  IT-Infrastructure
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/products"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Products
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/quality-assurance"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Quality Assurance
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/security"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  Security
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/expertise/user-experience"
                  className={`nav-dropdown-item ${dropdownOpen1 ? 'show' : ''}`}
                >
                  User Experince
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link href="/case-studies">Case Study</Nav.Link> */}
              {/* <Nav.Link href="/careers">Careers</Nav.Link> */}
              {/* <Nav.Link href="/#about">About</Nav.Link> */}
              <Nav.Link href="#contact">Contact</Nav.Link>
              
            </Nav>
            <Nav className="justify-content-end cstm-social-icon-header">
              <Nav.Link href="https://www.facebook.com/prosouls.snd">
                <img
                  src={fblogo}
                  width="20"
                  height="20"
                  className="d-inline-block align-top"
                  alt="Facebook Logo"
                />
              </Nav.Link>
              <Nav.Link href="https://www.instagram.com/prosoulsinc/?igshid=ZDdkNTZiNTM%3D">
                <img
                  src={Instalogo}
                  width="20"
                  height="20"
                  className="d-inline-block align-top"
                  alt="Instagram Logo"
                />
              </Nav.Link>
              {/* <Nav.Link href="https://www.linkedin.com/company/prosoulsinc/?originalSubdomain=pk">
                <img
                  src={lklogo}
                  width="20"
                  height="20"
                  className="d-inline-block align-top"
                  alt="LinkedIn Logo"
                />
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbarr;
