import "bootstrap/dist/css/bootstrap.min.css";

import "./footer.css";
import { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import Pakistan_icon from "../../assets/Pakistan-icon.webp";
import USA_icon from "../../assets/USA-icon.webp";
// import UK_icon from '../../assets/UK-icon.webp'
import site_logo from "../../assets/Prosouls-Logo-White-Background.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Footer() {
  const [apiKey, setApiKey] = useState("");
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const captchaRef = useRef(null);
  function onChange(value) {
    setIsCaptchaSuccess(true);
    console.log("captcha value: ", value);
  }
  const [formData, setFormData] = useState({
    projectType: "",
    fullName: "",
    emailAddress: "",
    phoneNumber: "+1",
  });
  console.log(formData.phoneNumber);
  const [emptyFields, setEmptyFields] = useState([]);

  useEffect(() => {
    // Call the geturl() function and set the returned value as the apiKey
    geturl();
  }, []);

  function geturl() {
    if (window.location.hostname === "localhost") {
      setApiKey("6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI");
    } else {
      setApiKey("6LdDQiMnAAAAAP11clIa6cN_ltOTZD4dlEE9XCG5");
    }
  }
  const [thankYouMessage, setThankYouMessage] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const fields = Object.keys(formData);
    const emptyFields = fields.filter((field) => formData[field] === "");

    setEmptyFields(emptyFields);
    if (
      isCaptchaSuccessful === true &&
      formData.emailAddress !== "" &&
      formData.fullName !== "" &&
      formData.phoneNumber !== "" &&
      formData.projectType !== ""
    ) {
      captchaRef.current.reset();
      // Send the data to the webhook
      fetch(
        "https://facebook.prosouls.net/contact.php?Name=" +
          formData.fullName +
          "&Email=" +
          formData.emailAddress +
          "&Phone=" +
          formData.phoneNumber +
          "&services=" +
          formData.projectType +
          "&hostname=Prosoulsinc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {})
        .catch((error) => {
          console.error("Error:", error);
          // Additional error handling code
        });

      setFormData({
        projectType: "",
        fullName: "",
        emailAddress: "",
        phoneNumber: "+1",
      });

      // Display the "Thank you" message for 2 seconds
      setThankYouMessage(true);
      setTimeout(() => {
        setThankYouMessage(false);
      }, 5000);
    }
  };

  return (
    <>
      <Container>
        {/* <ReactPlayer url={GlobeVideo} loop={true} playing={true} autoplay={true} controls={false} /> */}
        <div className="footer-bg">
          <h3 className="footer-bg-heading">
            READY TO DISCUSS <br /> YOUR PROJECT?
          </h3>
          <h5 className="footer-bg-para">CONTACT US</h5>
        </div>
        <div className="address-container">
          <div className="address-parent">
            <div className="prosouls-global">
              <div>
                <h4 className="clr-txt footer-content-prosouls-h4 ">
                  PROSOUL’S GLOBAL PRESENCE
                </h4>
                <h1 className="footer-content-prosouls-h1">
                  Now expanding to multiple cities across USA 
                </h1>
              </div>
            </div>
            <div className="address-column">
              <div className="address-column-flex">
                <div className="address-column-display address-usa">
                  <div className="address-desktop-behviour">
                  <img alt="usa" className="address-img" src={USA_icon} />
                    <p className="clr-txt mb-0">USA</p>
                  </div>
                  <div className="address-column-details desktop-d-none">
                    <p className="clr-txt mb-0">
                    418 N Main St Unit 126 Corona, CA, 92878-3416 United States
                      <br />
                      <a className="footer-link" href="tel:+18007876174">
                      (800) 787-6174
                      </a>
                    </p>
                  </div>
                </div>
                {/* <div className="address-column-display address-uk">
                  <div className="address-desktop-behviour">
                    <p className="clr-txt mb-0">UK</p>
                    <img alt="uk" className="address-img" src={UK_icon} />
                  </div>
                  <div className="address-column-details desktop-d-none">
                    <p className="clr-txt mb-0">Level 17,
                      Dashwood House,
                      69 Old Broad St,
                      London EC2M 1QS, <br />  <a className="footer-link" href="tel:+442045157418">+44 2045157418</a></p>
                  </div>
                </div> */}
                {/* <div className="address-column-display address-pakistan">
                  <div className="address-desktop-behviour">
                    <p className="clr-txt mb-0">Pakistan</p>
                    <img
                      alt="pakistan"
                      className="address-img"
                      src={Pakistan_icon}
                    />
                  </div>
                  <div className="address-column-details desktop-d-none">
                    <p className="clr-txt mb-0">
                      101 Mona Arcade Capt. Farees Bukhari Shaheed Road, Block
                      A, (Smchs), Karachi, 74000 <br />
                      <a className="footer-link" href="tel:+923317767685">
                        +92 (331) 7767685
                      </a>
                    </p>
                  </div>
                </div> */}
              </div>
              <div className="address-column-flex mob-d-none">
                <div className="address-column-details address-usa">
                  <p className="clr-txt mb-0">
                  418 N Main St Unit 126 Corona, CA, 92878-3416 United States
                    <br />
                    <a className="footer-link" href="tel:+18007876174">
                      (800) 787-6174
                    </a>
                  </p>
                </div>
                {/* <div className="address-column-details address-uk">
                  <p className="clr-txt mb-0">Level 17,
                    Dashwood House,
                    69 Old Broad St,
                    London EC2M 1QS, <br />  <a className="footer-link" href="tel:+442045157418">+44 2045157418</a></p>
                </div> */}
                {/* <div className="address-column-details address-pakistan">
                  <p className="clr-txt mb-0">
                    101 Mona Arcade Capt. Farees Bukhari Shaheed Road, Block A,
                    (Smchs), Karachi, 74000 <br />
                    <a className="footer-link" href="tel:+923317767685">
                      +92 (331) 7767685
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-parent" id="contact">
          <div className="prosouls-global">
            <div>
              <h5 className="clr-txt footer-content-prosouls-h4 hyphen-heading">
                <span className="horizintal-line"></span>LET’S TALK
              </h5>
              <h1 className="clr-txt my-4 cstm-mng-text-idea">
                Got an idea? <br /> Let’s get in touch.
              </h1>
            </div>
            <div className="mt-3">
              <p className="clr-txt mb-0">
                Let’s discuss your project and find out what we can do to
                provide value.
              </p>
            </div>
          </div>
          <div className="contact-us-column-form-parent">
            <p className="clr-txt mb-0">
              I am interested in discussing my ideas with you for
            </p>
            <div className="contact-us-column-flex">
              {emptyFields.length > 0 &&
                emptyFields.map((field) => (
                  <div className="alert-box">
                    <p className="alert-text">{`${field} is required`}</p>
                  </div>
                ))}
              {thankYouMessage && (
                <div className="alert-box-success">
                  <h3 className="alert-text">Thank you for contacting us!</h3>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="contact-us-form-footer">
                  <div className="contact-footers-col-6">
                    <select
                      name="projectType"
                      className="cstm-footer-form"
                      value={formData.projectType}
                      onChange={handleInputChange}
                    >
                      <option disabled value="">
                        Select Project Type
                      </option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="Mobile development">
                        Mobile development
                      </option>
                      <option value="Design and Branding">
                        Design and Branding
                      </option>
                      <option value="Brochure and Design">
                        Brochure and Design
                      </option>
                      <option value="Animation">Animation</option>
                      <option value="Social Media Marketing">
                        Social Media Marketing
                      </option>
                    </select>
                  </div>
                  <div className="contact-footers-col-6">
                    <input
                      type="text"
                      name="fullName"
                      className="cstm-footer-form"
                      placeholder="Your Full Name"
                      value={formData.fullName}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="contact-us-form-footer">
                  <div className="contact-footers-col-6">
                    <input
                      type="text"
                      name="emailAddress"
                      className="cstm-footer-form"
                      placeholder="Your Email Address"
                      value={formData.emailAddress}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="contact-footers-col-6">
                    {/* <input
                      type="number"
                      name="phoneNumber"
                      className="cstm-footer-form"
                      placeholder="Your Phone Number"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                    /> */}
                    <PhoneInput
                      country={"us"}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      enableAreaCodes={true}
                      placeholder="+1"
                      value={formData.phoneNumber}
                      onChange={(value) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          phoneNumber: "+" + value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <ReCAPTCHA
                  className="recaptcha cstm-recaptcha"
                  sitekey={apiKey}
                  ref={captchaRef}
                  onChange={onChange}
                  theme="info"
                />
                <div className="contact-us-form-footer">
                  <div className="contact-footers-col-6">
                    <div classsName="cstm-form-button-parent">
                      <button className="cstm-footer-form-button" type="submit">
                        LET’S GET IN TOUCH
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>

      {/* ============ Footer NewsLetter Container Start ============= */}

      <section className="footer-bg-white">
        <Container>
          <div className="newsletter-column-flex">
            <div className="footer-widget">
              <img
                alt="Prosouls"
                width="100%"
                height="100%"
                className="footer-logo-img"
                src={site_logo}
              />
               {/* <div ><a target="_blank" href="https://www.goodfirms.co/company/prosouls-inc" rel="noreferrer"><img className="goodfirms-image" src="https://assets.goodfirms.co/badges/color-sticker/it-services.svg" title="Prosouls Inc." alt="Prosouls inc."/></a></div> */}
              <div className="social-platform">
                <a
                  href="https://www.facebook.com/prosouls.snd"
                  aria-label="Read more about Prosouls on facebook"
                  className="footer-social-link"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/prosoulsinc/?igshid=ZDdkNTZiNTM%3D"
                  aria-label="Read more about Prosouls on Instagram"
                  className="footer-social-link"
                >
                  <InstagramIcon />
                </a>
                {/* <a
                  href="https://www.linkedin.com/company/prosoulsinc/?originalSubdomain=pk"
                  aria-label="Read more about Prosouls on Linkedin"
                  className="footer-social-link"
                >
                  <LinkedInIcon />
                </a> */}
              </div>
            </div>
            <div className="footer-widget">
              <h3 className="bg-white-text-clr mb-4">
                sign-in to stay tech savvy
              </h3>
              <p className="bg-white-text-clr">
                stay on top of emerging trends impacting your industry with us!
              </p>
              <div>
                <input
                  className="newsletter-input-footer"
                  type="email"
                  placeholder="EMAIL"
                />
              </div>
              <div>
                <input
                  className="newsletter-btn-footer"
                  type="submit"
                  value="Subscribe"
                />
              </div>
            </div>
            <div className="footer-widget">
              <h3 className="bg-white-text-clr mb-4">
                Estimate your project cost
              </h3>
              <p className="bg-white-text-clr">
                Use our project cost estimator to get a cost estimate for your
                project based on start agency pricing and compare with our
                pricing to measure your savings.
              </p>
              <div>
                <button className="newsletter-btn-footer get-free-pricing-btn">
                  GET FREE PRICING
                </button>
              </div>
            </div>
          </div>
          <div className="copyright-column-flex">
            <div className="copyright-widget">
              <ul className="footer-link-widget">
                <li>
                  <button className="copyright-footer-links">
                    TERMS AND CONDITIONS
                  </button>
                </li>
                <li>
                  <button className="copyright-footer-links">
                    PRIVACY POLICY
                  </button>
                </li>
                {/* <li>
                  <button className="copyright-footer-links">
                    CONTACT US
                  </button>
                </li>
                <li>
                  <button className="copyright-footer-links">
                    SITEMAP
                  </button>
                </li> */}
              </ul>
            </div>
            <div className="copyright-widget">
              <p className="copyright-footer-links m-0">
                COPYRIGHT 2023 PROSOULS ALL RIGHTS RESERVED.
              </p>
            </div>
          </div>
          {/* ============ Footer NewsLetter Container End ============= */}
        </Container>
      </section>
    </>
  );
}

export default Footer;
