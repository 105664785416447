import React, { useState } from 'react';
import './video-lightbox.css'
import CloseIcon from '@mui/icons-material/Close';
// import ReactPlayer from 'react-player'
// import ReelVideo from '../../../assets/reel.mp4'
// import VideoThumbnail from '../../../assets/logoHome.png'
// import ReactPlayer from 'react-player/youtube'
// import { ShowChartOutlined } from '@mui/icons-material';
// import ParticlesBackground from '../particle-background/particle-background';

const VideoLightbox = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
    {/* <ParticlesBackground /> */}
    <div>
      {/* <button onClick={handleButtonClick}>Open Lightbox</button> */}
      <button data-aos="slide-up" data-aos-easing="ease-in-cubic" onClick={handleButtonClick} class='home-glowing-btn'><span class='home-glowing-txt'><span class='home-faulty-letter'></span><span class='home-faulty-letter'>Show Reel</span></span> </button>
      {isOpen && (
        <div className="light-box-video-parent">
          {/* <ReactPlayer url={ReelVideo} playing={true} controls={true} /> */}
          <button className='light-box-close-btn' onClick={handleClose}><CloseIcon /></button>
          <h1>Coming Soon</h1>
        </div>
      )}
    </div>
    </>
  );
};

export default VideoLightbox;
