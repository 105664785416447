import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
// import Navbarr from "../../navbar/navbar";
import { useState, useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import Stylesheet from "./quality-assurance.css";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
import SecondModuleImage from "../../../assets/quality-assurance/quality-assurance-second-module.png";
import QualityAssuranceService1 from "../../../assets/quality-assurance/quality-assurance-software-test.png";
import QualityAssuranceService2 from "../../../assets/quality-assurance/quality-assurance-what-we-test.png";
import QualityAssuranceService3 from "../../../assets/quality-assurance/quality-assurance-industries.png";
import TechStackImage1 from "../../../assets/quality-assurance/quality-assurance-tech-stack-1.png";
import TechStackImage2 from "../../../assets/quality-assurance/quality-assurance-tech-stack-2.png";
import TechStackImage3 from "../../../assets/quality-assurance/quality-assurance-tech-stack-3.png";
import TechStackImage4 from "../../../assets/quality-assurance/quality-assurance-tech-stack-4.png";
import TechStackImage5 from "../../../assets/quality-assurance/quality-assurance-tech-stack-5.png";
import TechStackImage6 from "../../../assets/quality-assurance/quality-assurance-tech-stack-6.png";
import TechStackImage7 from "../../../assets/quality-assurance/quality-assurance-tech-stack-7.png";
import TechStackImage8 from "../../../assets/quality-assurance/quality-assurance-tech-stack-8.png";
import TechStackImage9 from "../../../assets/quality-assurance/quality-assurance-tech-stack-9.png";
import TechStackImage10 from "../../../assets/quality-assurance/quality-assurance-tech-stack-10.png";
import TechStackImage11 from "../../../assets/quality-assurance/quality-assurance-tech-stack-11.png";
import TechStackImage12 from "../../../assets/quality-assurance/quality-assurance-tech-stack-12.png";
import TechStackImage13 from "../../../assets/quality-assurance/quality-assurance-tech-stack-13.png";
import Aos from 'aos';
import 'aos/dist/aos.css'
function QualityAssurance() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="quality-assurance-expertise-banner">
        <div class="container">
          <div class="quality-assurance-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              QUALITY ASSURANCE
            </h5>
            <h1 className="clr-text-white">
              Ensuring digital perfection
              through rigorous, standards-based testing.
            </h1>
            <div className="quality-assurance-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="quality-assurance-expertise-second-module-parent">
        <div class="container">
          <div className="quality-assurance-expertise-second-module-flex">
            <div className="quality-assurance-expertise-second-module-column one">
              <div data-aos="slide-right" className="quality-assurance-parralax-container">
                <h1 className="quality-assurance-stroke-font">Quality</h1>
                <h1 className="quality-assurance-stroke-font">Asssurance</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="quality-assurance-expertise-second-module-column solid-bg">
              <div className="quality-assurance-expertise-solid-bg-content">
                <p className="clr-txt quality-assurance-content">Prosouls Inc’s quality assurance team applies
                  its advanced expertise to help your organization
                  create reliable, maintainable, and error-free
                  digital solutions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="quality-assurance-second-module-bg">
      <div className="container my-5">
        <section class="quality-assurance-third-module-section">
          <div class="quality-assurance-third-module-section__item column-one">
            <img className="img-fluid" src={SecondModuleImage} />
          </div>

          <div class="quality-assurance-third-module-section__item column-two">
            <h2 className="clr-txt m-0">
              SYSTEMS LIMITED QA AT A GLANCE
            </h2>
            <div class="quality-assurance-number-flex">
              <div data-aos="slide-up" class="quality-assurance-number-column aos-init aos-animate">
                <h1 class="clr-txt">40+</h1>
                <p class="clr-txt">Years of providing QA services</p>
              </div>
              <div data-aos="slide-up" class="quality-assurance-number-column aos-init aos-animate">
                <h1 class="clr-txt">750</h1>
                <p class="clr-txt">Live projects <br /> delivered</p>
              </div>
              <div data-aos="slide-up" class="quality-assurance-number-column aos-init aos-animate">
                <h1 class="clr-txt">120+</h1>
                <p class="clr-txt">Full-time QA professionals</p>
              </div>
              <div data-aos="slide-up" class="quality-assurance-number-column aos-init aos-animate">
                <h1 class="clr-txt">60</h1>
                <p class="clr-txt">Certified SDET resources</p>
              </div>
            </div>
          </div>
          <div className="quality-assurance-floating-container">
            <p className="clr-txt quality-assurance-second-module-content m-0">
              Quality assurance is the soul of software development. Our
              testing and quality management services are built around
              reliability, efficiency, and expertise to improve your digital
              assets across the entire development lifecycle.
            </p>
          </div>
        </section>
      </div>
      </section>
      <div className="container my-5">
        <div class="quaity-assurance-eight">
          <h1 className="clr-txt">OUR SERVICES</h1>
        </div>
      </div>
      <div className="container">
        <div className="products-expertise-cards-parent">
          <div className="quality-assurance-cards-flex">
            <div className="quality-assurance-cards-column column-one">
              <div className="quality-assurance-card-icon">
                <img className="quality-assurance-card-icon-img" src={QualityAssuranceService1} />
              </div>
              <div className="quality-assurance-card-title">
                <h3 className="clr-txt">Software Test Solutions</h3>
              </div>
              <div className="quality-assurance-card-details">
                <ul>
                  <li>Functionality Testing
                  </li>
                  <li>Usability Testing
                  </li>
                  <li>Test Automation
                  </li>
                  <li>Exploratory Testing
                  </li>
                  <li>Agile Testing with QAOps
                  </li>
                  <li>Load and Performance Testing
                  </li>
                  <li>Accessibility Testing
                  </li>
                  <li>Security Testing
                  </li>
                  <li>Cross browser & cross device Testing
                  </li>
                </ul>
              </div>
            </div>
            <div className="quality-assurance-cards-column column-two">
              <div className="quality-assurance-card-icon">
                <img className="quality-assurance-card-icon-img" src={QualityAssuranceService2} />
              </div>
              <div className="quality-assurance-card-title">
                <h3 className="clr-txt">What we Test</h3>
              </div>
              <div className="quality-assurance-card-details">
                <ul>
                  <li>Mobile Apps
                  </li>
                  <li>Web Applications
                  </li>
                  <li>Desktop Applications
                  </li>
                  <li>Legacy applications on main frames, AS/400, RISC
                  </li>
                  <li>Machines
                  </li>
                  <li>eCommerce sites
                  </li>
                  <li>APIs
                  </li>
                  <li>ETL
                  </li>
                  <li>Database/Datawarehouse Processes
                  </li>
                  <li>RPA Projects
                  </li>
                </ul>
              </div>
            </div>
            <div className="quality-assurance-cards-column column-three">
              <div className="quality-assurance-card-icon">
                <img className="quality-assurance-card-icon-img" src={QualityAssuranceService3} />
              </div>
              <div className="quality-assurance-card-title">
                <h3 className="clr-txt quality-assurance-mng-height">Industries</h3>
              </div>
              <div className="quality-assurance-card-details quality-assurance-mng-height-column">
                <ul>
                  <li>Retails & eCommerce
                  </li>
                  <li>Financial
                  </li>
                  <li>Telecom
                  </li>
                  <li>Education & Non profits
                  </li>
                  <li>Apparel
                  </li>
                  <li>Utilities
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container quality-assurance-our-clinets">
        <div className="quality-assurance-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">Technology stack</h1>
          <div class="quality-assurance-tech-container">
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage1} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage2} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage3} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage4} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage5} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage6} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage7} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage8} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage9} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage10} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage11} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage12} />
            </div>
            <div class="quality-assurance-tech-item">
              <img className="quality-assurance-tech-img" src={TechStackImage13} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default QualityAssurance;
