import "./App.css";
import Home from "./component/home/home";
import Navbarr from "./component/navbar/navbar";
import Mainscreen from "./component/Mainscreen";
import "bootstrap/dist/css/bootstrap.min.css";
// import Careers from "./component/careers/careers";
import MobileAppDevelopment from "./component/services/mobile-app-developtment/mobile-app-development"
import DesignAndBranding from "./component/services/design-and-branding/design-and-branding";
// import AnimationService from "./component/services/animation/animation";
import WebDevelopment from "./component/services/web-development/web-development";
import Marketing from './component/services/marketing/marketing';
import Brochure from './component/services/brochure/brochure';
import ApplicationIntegration from "./component/expertise/application-integration/application-integration";
import UserExperience from "./component/expertise/user-experience/user-experience";
import Security from "./component/expertise/security/security";
import ItInfrastructure from "./component/expertise/it infrastructure/it-infrastructure";
import BusinessProcessOutsourcing from "./component/expertise/business-process-outsourcing/business-process-outsourcing";
import Products from "./component/expertise/products/products";
import ApplicationModernization from "./component/expertise/application-modernization/application-modernization";
import ApplicationDevelopment from "./component/expertise/application-development/application-development";
import BusinessApplication from "./component/expertise/business-application/business-application";
import QualityAssurance from "./component/expertise/quality-assurance/quality-assurance";
import CloudTeam from "./component/expertise/cloud-team/cloud-team";
// import CaseStudyView from "./component/case_study-view/case-study";
// import CaseStudies from "./component/case-studies/case-studies";
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ServiceProvider } from "./context/Service";
function App() {
  // const [caseStudy, setCaseStudy] = useState([])

  // const fetchData = () => {
  //   fetch("https://cms.prosoulsinc.com/api/case_studies/getalldata")
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
  //       setCaseStudy(data.data)

  //     })
  // }
  // useEffect(() => {
  //   fetchData()

  // }, [])

  return (
    <div className="App">
      {/* <a
        href="https://wa.me/message/FECQSSEAOSPBD1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a> */}

<ServiceProvider>
      <Router>
        <Navbarr />
        <Routes>
          <Route path="/" element={<Mainscreen />} />
          {/* <Route path="/careers" element={<Careers />} /> */}
          {/* <Route path="/case-study" element={<CaseStudyView />} /> */}
          <Route path="/service/mobile-app-development" element={<MobileAppDevelopment />} />
          <Route path="/service/design-and-branding" element={<DesignAndBranding />} />
          {/* <Route path="/service/animation" element={<AnimationService />} /> */}
          <Route path="/service/website-development" element={<WebDevelopment />} />
          <Route path="/service/marketing" element={<Marketing />} />
          <Route path="/service/brochure" element={<Brochure />} />
          <Route path="/expertise/application-integration" element={<ApplicationIntegration />} />
          <Route path="/expertise/user-experience" element={<UserExperience />} />
          <Route path="/expertise/security" element={<Security />} />
          <Route path="/expertise/it-infrastructure" element={<ItInfrastructure />} />
          <Route path="/expertise/business-process-outsourcing" element={<BusinessProcessOutsourcing />} />
          <Route path="/expertise/products" element={<Products />} />
          <Route path="/expertise/application-modernization" element={<ApplicationModernization />} />
          <Route path="/expertise/application-development" element={<ApplicationDevelopment />} />
          <Route path="/expertise/business-application" element={<BusinessApplication />} />
          <Route path="/expertise/quality-assurance" element={<QualityAssurance />} />
          <Route path="/expertise/cloud-team" element={<CloudTeam />} />
          {/* <Route path="/case-studies" element={<CaseStudies />} />
          {caseStudy.map(data => (
            <Route path={data.route} element={<CaseStudyView title={data.title} description={data.description} metaTitle={data.meta_title} metaDescription={data.meta_description} metaKeyword={data.meta_keyword} />} />
          ))} */}
        </Routes>
      </Router>
      </ServiceProvider>
    </div>
  );
}

export default App;
