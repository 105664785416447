import "bootstrap/dist/css/bootstrap.min.css";
import img from "../../assets/team-png.png";
import "./careers.css";
import CareerBannerImage from '../../assets/career/Career-banner.webp'
import CareerPointImage1 from '../../assets/career/career-point-1.png'
import CareerPointImage2 from '../../assets/career/career-point-2.png'
import CareerPointImage3 from '../../assets/career/career-point-3.png'
import CareerPointImage4 from '../../assets/career/career-point-4.png'
import ParticlesBackground from "../global-component/particle-background/particle-background";
import Footer from "../footer/footer";
import Button from "react-bootstrap/Button";
import Contact from "../contact/contact";
import Navbarr from "../navbar/navbar";
import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
import { color } from "@mui/system";
import { useState, useEffect } from "react";
// import Test from "../test";
function Career() {
  const [careerJob, setCareerJob] = useState([])

  const fetchUserData = () => {
    fetch("https://cms.prosoulsinc.com/api/career_job/getalldata")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setCareerJob(data.data)

      })
  }

  const [isMobile, setMobile] = useState(window.innerWidth <= 900);

  const updateMedia = () => {
    setMobile(window.innerWidth <= 900);
  };
  useEffect(() => {
    fetchUserData()

  }, [])

  const sendEmail = () => {
    window.open("mailto:career@prosouls.net?subject=SendMail&body=Description");
  };

  return (
    <> {isMobile ? (
      <div className="career">
        <ParticlesBackground />
        <div className="container1234">
          <div className="parent">
            <img className="img1234" src={CareerBannerImage} alt="ContactUs"></img>
          </div>
          <div className="hello123">
            <h3 className="approach1234">
              Amazing Opportunities
              Await You!
              <p className="careerpara">
                At Prosouls Inc, we believe that our people are the driving
                force behind our success. We are a leading IT solutions company,
                dedicated to providing cutting-edge technology solutions to our
                clients across various industries.
              </p>
              <p className="careerpara">
                We are constantly growing and evolving, and we're looking for
                talented individuals like you to join our team and be a part of
                our exciting journey.
              </p>
            </h3>
          </div>
        </div>
        <div className="container career-second-module">
          <div class="career-second-module-heading eight">
            <h1 className="clr-txt">HIRING PROCESS</h1>
            <div className="application-development-manage-services-content">
              <p className="clr-txt text-center">
                Get ready to start your journey, learn about our hiring process!
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="career-cards-flex">
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage1} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Apply</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Scroll through the open
                  vacancies below and apply
                  for positions that align with
                  your particular skillset.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage2} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Review</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Our expert recruiters review
                  your application. They will
                  screen your résumé and find
                  the best fit for you.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage3} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Interviews</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Each department has its own
                  criteria, but they usually revolve
                  round the recruiter’s phone
                  screen, the hiring manager’s
                  interview, and a combination of
                  technical screens, panel interviews, and team presentations.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage4} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Onboarding</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Once you’ve received the
                  “Welcome Aboard”
                  message, our onboarding
                  experience will polish and
                  set you up for your
                  professional journey with
                  Prosouls Inc.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="jobopening">
          <h3 className="approach12345">
            JOB OPENING
            <p className="job">
              Browse through our current opening and in touch now. If you don't
              find any listed opening relevent to your profile, feel free to reach
              out anyway, and tell us why you might be a good fit email at  <span onClick={sendEmail} style={{ cursor: "pointer", color: "var(--prosouls-blue)" }}><u> career@prosouls.net.</u></span>
            </p>

          </h3>
          <Accordion defaultActiveKey="0" className="dropdowns">
            {careerJob.map(data => (
              <Accordion.Item eventKey={data.id}>
                <Accordion.Header>{data.title}</Accordion.Header>
                <Accordion.Body>
                  <h2 className="headingdd">{data.title}</h2>
                  <h4 className="frontsales">Company Overview: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.company_overview + "" }} />
                  <h4 className="frontsales">Role: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.role + "" }} />
                  <h4 className="frontsales">Responsibilities: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.responsibilities + "" }} />
                  <h4 className="frontsales">Requirements: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.requirements + "" }} />


                  <h3 className="jobtype">JOB TYPE: {data.type}</h3>
                  <h3 className="jobtype1">Market Competitive Salary</h3>

                  <Button onClick={sendEmail} className="btn45" variant="outline-primary">
                    Apply now
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}

          </Accordion>
        </div>
        {/* <Contact /> */}
        <Footer />
      </div>) : (
      <div className="career">
        <ParticlesBackground />
        <div className="container1234">
          <div className="parent">
            <img className="img1234" src={CareerBannerImage} alt="ContactUs"></img>
          </div>
          <div className="hello123">
            <h3 className="approach1234">
              Amazing Opportunities
              Await You!
              <p className="careerpara">
                At Prosouls Inc, we believe that our people are the driving
                force behind our success. We are a leading IT solutions company,
                dedicated to providing cutting-edge technology solutions to our
                clients across various industries.
              </p>
              <p className="careerpara">
                We are constantly growing and evolving, and we're looking for
                talented individuals like you to join our team and be a part of
                our exciting journey.
              </p>
            </h3>
          </div>
        </div>
        <div className="container career-second-module">
          <div class="career-second-module-heading eight">
            <h1 className="clr-txt">HIRING PROCESS</h1>
            <div className="application-development-manage-services-content">
              <p className="clr-txt text-center">
                Get ready to start your journey, learn about our hiring process!
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="career-cards-flex">
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage1} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Apply</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Scroll through the open
                  vacancies below and apply
                  for positions that align with
                  your particular skillset.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage2} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Review</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Our expert recruiters review
                  your application. They will
                  screen your resume and find
                  the best fit for you.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage3} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Interviews</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Each department has its own
                  criteria, but they usually revolve
                  round the recruiter’s phone
                  screen, the hiring manager’s
                  interview, and a combination of
                  technical screens, panel interviews, and team presentations.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage4} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Onboarding</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Once you’ve received the
                  “Welcome Aboard”
                  message, our onboarding
                  experience will polish and
                  set you up for your
                  professional journey with
                  Prosouls Inc.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="jobopening">
          <h3 className="approach12345">
            JOB OPENING
            <p className="job">
              Browse through our current opening and in touch now. If you don't
              find any listed opening relevent to your profile, feel free to reach
              out anyway, and tell us why you might be a good fit email at  <span onClick={sendEmail} style={{ cursor: "pointer", color: "var(--prosouls-blue)" }}><u> career@prosouls.net.</u></span>
            </p>
          </h3>
          <Accordion defaultActiveKey="0" className="dropdowns">
            {careerJob.map(data => (
              <Accordion.Item eventKey={data.id}>
                <Accordion.Header>{data.title}</Accordion.Header>
                <Accordion.Body>
                  <h2 className="headingdd">{data.title}</h2>
                  <h4 className="frontsales">Company Overview: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.company_overview + "" }} />
                  <h4 className="frontsales">Role: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.role + "" }} />
                  <h4 className="frontsales">Responsibilities: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.responsibilities + "" }} />
                  <h4 className="frontsales">Requirements: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.requirements + "" }} />


                  <h3 className="jobtype">JOB TYPE: {data.type}</h3>
                  <h3 className="jobtype1">Market Competitive Salary</h3>

                  <Button onClick={sendEmail} className="btn45" variant="outline-primary">
                    Apply now
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        {/* <Contact /> */}
        <Footer />
      </div>
    )} </>
  );
}

export default Career;
