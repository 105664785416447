import globe from "../../assets/globe.jpg";
import logoHome from "../../assets/logoHome.png";
import "./home.css";
// import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
// import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import { Helmet } from "react-helmet";
import VideoLightbox from "../global-component/video-lightbox/video-ligthbox";
// import { gsap } from "gsap";
// const boxVariant = {
//     visible: { opacity: 1, scale: 1, transition: { duration: 0.1 } },
//     hidden: { opacity: 0, scale: 0 }
//   };

function Home() {


  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      

      <div id="home1" className="Home1">
        <div className="banner-image-parent">
          <img width="100%" height="100%" className="center" alt="Banner Globe" src={globe} />
        </div>
      </div>
      <div className="home">
        <div data-aos="zoom-in">
          <img width="12%" height="100%" className="homelogo" alt="Prosouls Symbol" src={logoHome}/>
        </div>

        
          <div >
            <h3  className="Heading">CREATING SENSE DIGITALLY</h3>
          </div>
          <div >
            <p className="text">
            Prosouls is a group of dedicated and creative individuals who work with great focus and positivity to achieve the best results possible. Having experience of decades, our vision is to provide well-designed, targeted digital communications. We ensure the elevation of clients' prominence and the realization of their concepts through top-notch IT services
            </p>
          </div>
        <VideoLightbox />
      </div>
    </>
  );
}

export default Home;
