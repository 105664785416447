import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
// import Navbarr from "../../navbar/navbar";
import { useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import "./security.css";
import Footer from "../../footer/footer";
import TableImage from '../../../assets/security-module.png'
import Aos from 'aos';
import 'aos/dist/aos.css'
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
function Security() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="security-expertise-banner">
        <div class="container">
          <div class="security-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              SECURITY
            </h5>
            <h1 className="clr-text-white">
              Safeguarding your
              valuable information &
              IT investments.
            </h1>
            <div className="security-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="security-expertise-second-module-parent">
        <div class="container">
          <div className="security-expertise-second-module-flex">
            <div className="security-expertise-second-module-column one">
              <div data-aos="slide-right" className="security-parralax-container">
                <h1 className="security-stroke-font">Cyber</h1>
                <h1 className="security-stroke-font">Security</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="security-expertise-second-module-column solid-bg">
              <div className="security-expertise-solid-bg-content">
                <p className="clr-txt security-content">Your information is your most important business
                  asset. Your organization needs to invest in ensuring
                  its security, integrity, and confidentiality to mitigate
                  risk.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p className="clr-text-white text-center">
          Businesses and public-sector organizations around the world trust Prosouls Inc to ensure that their sensitive business information
          remains secure and your internal and customer-facing digital services continue to function disruption-free. We apply the latest data
          privacy and InfoSec standards to prevent unauthorized access, suspicious activity, service outages, and data loss.
        </p>
      </div>
      <div className="security-expertise-fourth-module-parent">
        <div className="container">
          <div className="security-table-section">
            <table className="bordered security-table" >
              <tbody>
                <tr>
                  <td>
                    <p className="clr-txt-blue">SECURITY OPERATIONS CENTER (SOC)</p>
                    <p className="clr-txt">Our managed security operations center
                      (SOC) service provides 24x7x365 proactive
                      security monitoring, threat intelligence,
                      vulnerability management, forensic
                      analysis, and incident response.</p>
                    <p className="clr-txt">Our managed SOC service is a
                      cost-effective way for organizations to
                      gain all the benefits of an SOC without the
                      associated outlay and overhead.</p>
                  </td>
                  <td>
                    <img alt="Security" className="security-table-img" src={TableImage} />
                  </td>
                  <td>
                    <p className="clr-txt-blue">INTRUSION DETECTION</p>
                    <p className="clr-txt">Prosouls Inc identifies weak points in your
                      infrastructure so they can be hardened
                      against attacks.</p>
                    <p className="clr-txt">Our experienced and industry-certified
                      testers excel at testing network
                      infrastructure, web apps, and mobile apps
                      according to industry-recognized standards
                      like SANS, NIST, and CIS</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="clr-txt-blue">SECURITY SOLUTION
                      IMPLEMENTATION</p>
                    <p className="clr-txt">As an IBM Platinum Business Partner,
                      Prosuols Inc can help you deploy and
                      configure powerful IBM security solutions,
                      including IBM Guardium and IBM QRadar.</p>
                    <p className="clr-txt">Our experts quickly assess your unique
                      business needs and rapidly deploy the right
                      mix of security solutions</p>
                  </td>
                  <td><p className="clr-txt-blue">DIGITAL FORENSICS</p>
                    <p className="clr-txt">Incidents related to computer security and digital
                      evidence are on the rise. Prosouls Inc’s
                      forensic analysis services are key to our clients’
                      incident response capabilities.</p>
                    <p className="clr-txt">We provide electronic data acquisition and
                      analysis, electronic evidence, common evidence
                      recovery, litigation support, mobile phone
                      forensics, and other advanced services. We can
                      provide services on the scene if required, or the
                      affected equipment can be securely delivered to
                      our lab for examination.</p>
                  </td>
                  <td><p className="clr-txt-blue">VULNERABILITY ASSESSMENT &
                    PENETRATION TESTING</p>
                    <p className="clr-txt">Prosouls Inc provides industry-leading threat
                      prevention and management solutions to protect you from external and internal threats to
                      your computer networks.</p>
                    <p className="clr-txt">We design, implement, tune, monitor, and
                      manage your intrusion prevention and
                      intrusion detection systems as a service to
                      significantly enhance your protection and
                      compliance at an unbeatable price point</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Security;
